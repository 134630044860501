//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  data() {
    const MP = this.$store.state.connections.marketplace.title === "Wildberries" ? 'WB' : 'OZ';
    const defaultColumns = MP == 'WB' ? [{
      title: "SKU",
      show: true,
      name: "nmid",
      type: "text",
      width: 110,
      filter: "text",
      noOverflow: true
    }, {
      title: "Артикул",
      show: true,
      name: "sku",
      type: "text",
      width: 160,
      filter: "text",
      noOverflow: true
    }, {
      title: "Бренд",
      show: true,
      name: "brand",
      type: "text",
      filter: "text",
      width: 160
    }, {
      title: "Наименование",
      show: true,
      name: "subject",
      type: "text",
      filter: "text",
      width: 220,
      getImage: item => item.image
    }, {
      title: `Размер`,
      show: true,
      name: "techSize",
      type: "text",
      filter: "text",
      width: 100
    }, {
      title: `Категория`,
      show: true,
      name: "category",
      type: "text",
      filter: "text",
      width: 200
    }, {
      title: `Цена`,
      show: true,
      name: "price",
      type: "money",
      width: 120,
      filter: "numberRange"
    }, {
      title: `Магазин`,
      show: true,
      name: "user_key_name",
      type: "text",
      width: 120,
      filter: "text"
    }, {
      title: `Коэффициент`,
      show: true,
      name: "ratio",
      type: "number",
      width: 140,
      filter: "numberRange"
    }, {
      title: `Текущий остаток (всего)`,
      show: true,
      name: "stock_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Кол-во к поставке (всего)`,
      show: true,
      name: "delivery_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Дней в наличии (всего)`,
      show: true,
      name: "days_in_stock",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Кол-во продаж (всего)`,
      show: true,
      name: "total_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Cкорость продаж (всего)`,
      show: true,
      name: "speed_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }] : [
    // OZ
    {
      title: "SKU",
      show: true,
      name: "sku",
      type: "text",
      width: 110,
      filter: "text",
      noOverflow: true
    }, {
      title: "Артикул",
      show: true,
      name: "offer_id",
      type: "text",
      width: 160,
      filter: "text",
      noOverflow: true
    }, {
      title: "Наименование",
      show: true,
      name: "name",
      type: "text",
      filter: "text",
      width: 500,
      getImage: item => item.image
    },
    /* &:
    {
        title: `Категория`,
        show: true,
        name: "category_name",
        type: "text",
        filter: "text",
        width: 200,
    },
    */

    {
      title: `Цена`,
      show: true,
      name: "price",
      type: "money",
      width: 120,
      filter: "numberRange"
    }, {
      title: `Магазин`,
      show: true,
      name: "user_key_name",
      type: "text",
      width: 120,
      filter: "text"
    }, {
      title: `Коэффициент`,
      show: true,
      name: "ratio",
      type: "number",
      width: 140,
      filter: "numberRange",
      sort: false
    }, {
      title: `Текущий остаток (всего)`,
      show: true,
      name: "stock_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Кол-во к поставке (всего)`,
      show: true,
      name: "delivery_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Дней в наличии (всего)`,
      show: true,
      name: "days_in_stock",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Кол-во продаж (всего)`,
      show: true,
      name: "total_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `Cкорость продаж (всего)`,
      show: true,
      name: "speed_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }];
    return {
      columns: defaultColumns,
      defaultColumns,
      MP
    };
  },
  methods: {
    async onUpdate(event) {
      const updatedItems = event.update();
      for (const item of updatedItems) {
        if (this.$store.state.connections.marketplace.title === "Wildberries") {
          await this.$store.dispatch("connections/updateWbCalcDeliveryProducts", {
            item
          });
        }
        if (this.$store.state.connections.marketplace.title === "OZON") {
          await this.$store.dispatch("connections/updateOzCalcDeliveryProducts", {
            item
          });
        }
      }
      this.refresh();
    },
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      const data = await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbCalcDeliveryProducts", "connections/getOzCalcDeliveryProducts", {}, {});

      //console.log('321: this.reportSettings.clusters=',this.reportSettings.clusters)
      //console.log('322: data=',data)
      //console.log('322: data.clusters=',data.clusters)

      let aStorePlace = [];

      //console.log('337: this.MP=',this.MP)
      //console.log('338: data=',data)

      if (this.MP == 'OZ') {
        var _data$clusters;
        let clusters = [];
        data === null || data === void 0 || (_data$clusters = data.clusters) === null || _data$clusters === void 0 || _data$clusters.forEach(el => {
          ////console.log('325: el=',el)
          if (this.reportSettings.clusters.includes(el.cluster_id)) {
            clusters.push({
              id: el.cluster_id,
              name: el.cluster_name
            });
          }
        });

        //console.log('336: clusters=',clusters)

        let check = new Set();
        clusters = clusters.filter(obj => !check.has(obj["name"]) && check.add(obj["name"]));
        ////console.log('343: clusters=',clusters)
        aStorePlace = clusters;
      } else {
        let stores = [];

        //console.log('362: this.reportSettings.stores=',this.reportSettings.stores)

        //почему-то невозвращается в 'data'
        let stores2 = await this.$store.dispatch("connections/getWbStores", {
          datasource: "wb"
        });
        //console.log('369: stores2=',stores2)

        data.stores = stores2.data;
        //console.log('363: data.stores=',data.stores)

        this.reportSettings.stores.forEach(item => {
          var _data$stores;
          data === null || data === void 0 || (_data$stores = data.stores) === null || _data$stores === void 0 || _data$stores.forEach(el => {
            if (item.includes(el.id)) {
              stores.push({
                id: el.id,
                name: el.group_name,
                groupId: el.group_id
              });
            }
          });
        });
        let check = new Set();
        stores = stores.filter(obj => !check.has(obj["name"]) && check.add(obj["name"]));
        aStorePlace = stores;
      }

      //console.log('379: aStorePlace=',aStorePlace)

      if (query.action !== "export") {
        this.columns = this.defaultColumns;
        aStorePlace.forEach(el => {
          this.columns = [...this.columns, {
            title: `Текущий остаток (${el.name})`,
            show: true,
            name: this.MP == 'OZ' ? `stock_quantity_c${el.id}` : `stock_quantity_g${el.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true,
            sort: false
          }, {
            title: `Кол-во к поставке (${el.name})`,
            show: true,
            name: this.MP == 'OZ' ? `delivery_quantity_c${el.id}` : `delivery_quantity_g${el.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true,
            sort: false
          }, {
            title: `Кол-во продаж (${el.name})`,
            show: true,
            name: this.MP == 'OZ' ? `total_sales_c${el.id}` : `total_sales_g${el.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true,
            sort: false
          }, {
            title: `Cкорость продаж (${el.name})`,
            show: true,
            name: this.MP == 'OZ' ? `speed_sales_c${el.id}` : `speed_sales_g${el.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true,
            sort: false
          }];
        });
      }

      //console.log('443: this.columns=',this.columns)

      return data;
    }
  }
};